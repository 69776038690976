import bgImage from './assets/img/bg-img.jpeg';

function App() {
  return (
    <div className="bg-[#7a967f] flex text-center flex-col justify-center items-center w-full h-screen">
            <img className="max-w-full hover:scale-105 transition-all duration-300 p-4" src={bgImage} alt="iShipFromTurkey"/>
            <p className="max-w-full drop-shadow w-[500px] p-4 mt-2 text-amber-50">While I'm trying to find an answer to these questions, there isn't a lot to do on IShipFromTurkey as of the moment for you. Rest assured, I will revert you with the best online shopping experience within a year (I hope so).
            </p>
        <p className="max-w-full w-[500px] drop-shadow p-4 mt-2 text-amber-50">Should you need further information about IShipFromTurkey, you can contact me via this email: <a
          className="text-black hover:text-gray-800 transition-all duration-300 font-bold"  href="mailto:buluttanrisever@gmail.com">buluttanrisever@gmail.com</a></p>
    </div>
  );
}

export default App;
